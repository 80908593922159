import { Store, setObservables } from '@sowlutions-tech/next/common/stores'
import { isNil } from 'lodash'

export default class MobxStore extends Store {
  constructor(props) {
    super(props)
  }

  async storeApiResponse({ apiName, observableName, onSuccess = () => {}, onFailure = console.error, params = {} }) {
    let apiResponse

    await this.api()[apiName](params)
      .then(resp => {
        const success = !isNil(resp.success) ? resp.sucess : resp.data ? resp.data.success : true
        if (success !== false) {
          apiResponse = resp || {}

          if (observableName) {
            this[observableName] = apiResponse.data || apiResponse
          }

          if (onSuccess) {
            onSuccess(apiResponse)
          }
        } else {
          console.error(resp)
        }
      })
      .catch(error => {
        onFailure(error)
      })

    return apiResponse
  }
}

export { setObservables }