import React from 'react'
import PropTypes from 'prop-types'

import * as style from './styles'

/**
 * <Describe the Message component here>
 *
 * @component
 * @usage import Message from '~/components/Message'
 * @example
 * <Add an example of how Message is used here>
 */
const Message = ({ children, type, ...extra }) => {
  if (!children) {
    return null
  }

  return (
    <div css={style.message(type)} {...extra}>
      {children}
    </div>
  )
}

Message.propTypes = {
  type: PropTypes.oneOf(['error', 'success']),
}

Message.defaultProps = {
  type: 'error',
}

export default Message
