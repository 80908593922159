import Store, { setObservables } from './Store'
import Authentication from '~/network/Authentication.js'
import { action, observable, toJS } from 'mobx'
import { isEmpty } from 'lodash'

const OBSERVABLES = {
  _cards: { type: observable, defaultValue: [] },
  fetchCards: { type: action },
  addCard: { type: action },
}

class CardsStore extends Store {
  constructor(initialData = {}) {
    super(initialData)
    setObservables(this, initialData, OBSERVABLES)
  }

  service() {
    return new Authentication(this.rootStore)
  }

  async fetchCards() {
    const cardsCall = await this.service().api().getCards({ limit: 100, offset: '0' })
    this.setCards(cardsCall)
    return cardsCall
  }

  async addCard(params) {
    const addCardCall = await this.service().api().addCard(params)
    return addCardCall
  }

  setCards(value) {
    this._cards = value
  }

  get cards() {
    return toJS(this._cards)
  }
}

export default CardsStore
