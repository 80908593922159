import Service from './Service'

export const SERVICES = {
  login: {
    type: 'post',
    serviceBase: 'signin',
  },
  getUserData: {
    type: 'get',
    urlParam: '/info',
  },
  getCards: {
    type: 'get',
    urlParam: '/cards',
  },
  addCard: {
    type: 'post',
    urlParam: '/cards',
  },
}

/**
 * Instance of Authentication Service.
 *
 * @class
 * @usage `import Authentication from '~/network/Authentication'`
 */
export default class Authentication extends Service {
  constructor(props) {
    super(props)
    this.services = SERVICES
  }

  serviceBase() {
    return 'users'
  }
}
