import { css } from '@emotion/react'
import { transparentize } from 'polished'
import colors from '~/styles/colors'
import { transition } from '~/styles/constants'

export const message = type => css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  min-height: 5rem;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  ${transition};

  ${type === 'error' && `
    background-color: ${transparentize(0.5, colors.red)};
  `};

  ${type === 'success' && `
    background-color: ${transparentize(0.5, colors.green)};
  `};
`